const appConfigs = {
    LOGGER: {
      REDUX: false
    },
    API: {
      ROOT_URL: 'https://api.hotqa.com/api',
    },
    REQUEST: {
      TIMEOUT: 120000
    },
    AUTHENTICATED_DATA: {
      EXPIRED_TIME: 365
    }
  }
  
  export default appConfigs