export default `
.react-datepicker-wrapper{
    width: 100% !important;
}
p {
    font-size:16px;
}
div#loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
div#loading .blockUI.blockOverlay {
    z-index: 1060 !important;
    opacity: 0.3 !important;
}
div#loading .blockUI.blockMsg.blockElement {
    z-index: 1061 !important;
}
div#root {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    overflow: hidden;
}
div.route-view {
    position: relative;
    width: 100%;
    height: 100%;
}

div.full-height {
    height: 100%;
}

.hidden {
    display: none;
}

option.default {
    display: none
}

hr.match-line {
    border: 1px solid red;
}

hr.match-green-line {
    border: 1px solid #0abb87;
    margin-top: 5px;
}

div.col-no-padding {
    padding: 0 !important;
}

div.reorder-list {
    padding: 40px 0;
    display: inline-block;
}

div.reorder-element {
    padding: 8px;
    margin-right: 8px;
    background-color: white;
    border: 2px solid orange;
    border-radius: 20px;
    display: inline-block;
    min-width:64px;
    text-align: center;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

div.matching-element {
    padding: 20px;
    background-color: white;
    border: 2px solid lightgrey;
    border-radius: 20px;
    min-width: 80px;
    height: auto;
    display: inline-block;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
}

div.matching-element-img {
    padding: 8px;
    background-color: white;
    border: 2px solid lightgrey;
    border-radius: 20px;
    min-width: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
}

div.draggable-element {
    border: 2px solid orange;
}

img.matching_img {
    max-width: 100%;
    width: 75px;
    height: 75px;
}



.pace .pace-progress {
    background: #ff0000;
}
p.mb-0 p {
    margin-bottom:0px;
}
p.kt-notification-v2__item-title p{
    margin-bottom:0px;
}


.toast .toast-title {
    color: #fff;
}



.ps > .ps__rail-y,
.ps > .ps__rail-x {
    z-index: 98;
}

.ps > .ps__rail-y > .ps__thumb-y,
.ps > .ps__rail-x > .ps__thumb-x {
    background: #95a5a6;
}

.ps > .ps__rail-y,
.ps > .ps__rail-y > .ps__thumb-y {
    width: 8px !important;
}

.ps > .ps__rail-x,
.ps > .ps__rail-x > .ps__thumb-x {
    height: 8px !important;
}

.kt-header .kt-header-menu-wrapper img {
    max-width: 100px;
    max-height: 40px;
    margin: 10px 25px;
}

.kt-header.embed-header {
    background: #1a1a27;
}




.splash-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.splash-page .logo-image {
    max-width: 150px;
    max-height: 150px;
}
.splash-page .loading-animation {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
    margin-top: 20px;
}
.splash-page .loading-animation div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: #FF0000;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.splash-page .loading-animation div:nth-child(1) {
    left: 6px;
    animation: loading-animation1 0.6s infinite;
}
.splash-page .loading-animation div:nth-child(2) {
    left: 6px;
    animation: loading-animation2 0.6s infinite;
}
.splash-page .loading-animation div:nth-child(3) {
    left: 26px;
    animation: loading-animation2 0.6s infinite;
}
.splash-page .loading-animation div:nth-child(4) {
    left: 45px;
    animation: loading-animation3 0.6s infinite;
}
@keyframes loading-animation1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes loading-animation3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}
@keyframes loading-animation2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(19px, 0);
    }
}



.main-page.fake-body {
    height: 100%;
    display: flex;
}

.main-page .kt-aside__brand-logo img {
    max-width: 100px;
    max-height: 30px;
}
.main-page .kt-header-mobile__logo img {
    max-width: 100px;
    max-height: 30px;
    width: auto;
    height: auto;
}



#main_dashboard_examinee_test_user_list .kt-portlet__body {
    position: relative;
}
#main_dashboard_examinee_test_user_list .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_dashboard_examinee_test_user_list .kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__pic .kt-badge {
    height: 100px;
    width: 100px;
    font-size: 40px;
}
#main_dashboard_examinee_test_user_list .kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__info span {
    font-weight: 400;
    margin-right: 5px;
}
#main_dashboard_examinee_test_user_list .kt-widget5 .kt-widget5__item .kt-widget5__content > .kt-widget5__stats {
    padding-right: 3rem;
}
#main_dashboard_examinee_test_user_list .kt-widget5 .kt-widget5__item .kt-widget5__content .kt-widget5__stats:last-child {
    padding-right: 0;
}
#main_dashboard_examinee_test_user_list .kt-portlet__foot {
    padding: 0
}
#main_dashboard_examinee_test_result_list .kt-portlet__body {
    position: relative;
}
#main_dashboard_examinee_test_result_list .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_dashboard_examinee_test_result_list .kt-portlet__foot {
    padding: 0
}



#main_user_list_page .kt-section {
    margin-bottom: 0;
}
#main_user_list_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_user_list_page .avatar-upload {
    position: fixed !important;
    top: -100px;
    left: -100px;
}
#main_user_list_page .kt-widget__img {
    object-fit: cover;
    height: 90px;
    width: 90px;
    // border-radius: 50%;
}



#main_application_list_page .kt-section {
    margin-bottom: 0;
}
#main_application_list_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_application_list_page .logo-upload {
    position: fixed !important;
    top: -100px;
    left: -100px;
}
#main_application_list_page .kt-widget__img {
    object-fit: cover;
    height: 90px;
    width: 90px;
    // border-radius: 50%;
}



#main_test_list_page .kt-section {
    margin-bottom: 0;
}
#main_test_list_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}

.assign_reviewer_modal_add_reviewer_modal .form-group {
    margin-bottom: 0;
}

.assign_reviewer_modal_add_reviewer_modal .select2.select2-container {
    width: 100% !important;
}

.assign_reviewer_modal .modal-content .modal-header .modal-title a {
    margin-left: 10px;
}

.assign_reviewer_modal .kt-section {
    margin-bottom: 0;
}

.assign_reviewer_modal .row {
    margin-bottom: 10px;
}

.assign_reviewer_modal .kt-user-card-v2 .kt-user-card-v2__details {
    padding-right: 0.5rem;
}

.assign_reviewer_modal .kt-user-card-v2 {
    padding: 10px;
    border: 1px solid #ebedf2;
    border-radius: 5px;
    position: relative;
} 

.assign_reviewer_modal .kt-user-card-v2 > a {
    position: absolute;
    right: 10px;
    font-size: 20px;
} 

.assign_reviewer_modal .kt-user-card-v2 .kt-user-card-v2__pic img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    object-fit: cover;
}




#main_test_report_page .kt-section {
    margin-bottom: 0;
}
#main_test_report_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_test_report_page #main_test_report_detail_statistic_wrapper td span.number {
    font-size: 15px;
}



#main_test_users_page .kt-section {
    margin-bottom: 0;
}
#main_test_users_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}

#main_test_form_page .kt-section {
    margin-bottom: 0
}
#main_test_form_page .kt-section .kt-section__content p {
    margin-bottom: 0
}
#main_test_form_page .kt-widget .kt-widget__top .kt-widget__pic {
    font-size: 40px;
}
#main_test_form_page .kt-widget .kt-widget__top .kt-widget__content .kt-widget__info {
    flex-direction: column;
}
#main_test_form_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc {
    width: 100%;
    line-height: 25px;
}
#main_test_form_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress {
    max-width: unset;
    margin-bottom: 5px;
}
#main_test_form_page .kt-widget.kt-widget--user-profile-3 .kt-widget__bottom:nth-child(3) {
    border-top: none;
}
#main_test_form_page .extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#main_test_form_page .extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_form_page .extra-block > div:first-child{
    margin-top: 0;
}
#main_test_form_page .extra-block p {
    width: 100%;
    margin-bottom: 0;
    font-size: 1.8rem;
}
#main_test_form_page .extra-block img {
    width: 100%;
    height: 400px;
    object-fit: contain;
}
#main_test_form_page .extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_form_page .extra-block audio:focus {
    outline: none !important;
}
#main_test_form_page .extra-block video {
    width: 100%;
}
#main_test_form_page .kt-notification .kt-notification__item:after {
    content: ''
}
#main_test_form_page .kt-notification .kt-notification__item {
    flex-direction: column;
    padding: 15px;
}
#main_test_form_page .kt-notification .kt-notification__item h5 {
    width: 100%;
}
#main_test_form_page .kt-notification .kt-notification__item h6 {
    width: 100%;
}
#main_test_form_page .kt-notification__item {
    margin-top: 10px
}
#main_test_form_page .kt-notification__item p {
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 18px;
    color: #646c9a;
}
#main_test_form_page .kt-notification__item audio {
    margin-top: 20px;
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_form_page .kt-notification__item audio:focus {
    outline: none !important;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 {
    width: 100%;
    margin-top: 10px;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item {
    padding: 5px;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item.selected .kt-notification-v2__item-icon {
    background: #646c9a;
    color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; 
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-badge {
    margin-left: 5px;
    min-width:30px;
    width: 30px;
    height: 30px;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper img {
    max-width: 100px;
    max-height: 100px;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper audio {
    height: 50px;
    width: 200px;
    background: #f1f3f4;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper audio:focus {
    outline: none !important;
}
#main_test_form_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__itek-wrapper video {
    max-width: 200px;
    max-height: 200px;
}

#main_test_form_page .moveArrows .fa-arrow-circle-up:hover, #main_test_form_page .moveArrows .fa-arrow-circle-down:hover  {
    color: #0abb87;
}

#main_test_form_page div.full-width {
    width: 100%;
}



#main_test_results_page .kt-section {
    margin-bottom: 0;
}
#main_test_results_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}



#main_test_review_page .kt-section {
    margin-bottom: 0
}
#main_test_review_page .kt-section .kt-section__content p {
    margin-bottom: 0
}
#main_test_review_page .kt-widget .kt-widget__top .kt-widget__pic {
    font-size: 40px;
}
#main_test_review_page .kt-widget .kt-widget__top .kt-widget__content .kt-widget__info {
    flex-direction: column;
}
#main_test_review_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc {
    width: 100%;
    line-height: 25px;
}
#main_test_review_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress {
    max-width: unset;
    margin-bottom: 5px;
}
#main_test_review_page .kt-widget.kt-widget--user-profile-3 .kt-widget__bottom:nth-child(3) {
    border-top: none;
}
#main_test_review_page .section-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#main_test_review_page .section-extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_review_page .section-extra-block > div:first-child{
    margin-top: 0;
}
#main_test_review_page .section-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_review_page .section-extra-block img {
    width: 100%;
}
#main_test_review_page .section-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_review_page .section-extra-block audio:focus {
    outline: none !important;
}
#main_test_review_page .section-extra-block video {
    width: 100%;
}
#main_test_review_page .answer-top-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
#main_test_review_page .answer-top-extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_review_page .answer-top-extra-block > div:first-child {
    margin-top: 0;
}
#main_test_review_page .answer-top-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_review_page .answer-top-extra-block img {
    width: 100%;
}
#main_test_review_page .answer-top-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_review_page .answer-top-extra-block audio:focus {
    outline: none !important;
}
#main_test_review_page .answer-top-extra-block video {
    width: 100%;
}
#main_test_review_page .answer-left-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#main_test_review_page .answer-left-extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_review_page .answer-left-extra-block > div:first-child {
    margin-top: 0;
}
#main_test_review_page .answer-left-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_review_page .answer-left-extra-block img {
    width: 100%;
}
#main_test_review_page .answer-left-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_review_page .answer-left-extra-block audio:focus {
    outline: none !important;
}
#main_test_review_page .answer-left-extra-block video {
    width: 100%;
}
#main_test_review_page .kt-notification .kt-notification__item:after {
    content: ''
}
#main_test_review_page .kt-notification .kt-notification__item {
    flex-direction: column;
    padding: 15px;
}
#main_test_review_page .kt-notification .kt-notification__item h5 {
    width: 100%;
}
#main_test_review_page .kt-notification .kt-notification__item h5 a {
    font-size: 20px;
}
#main_test_review_page .kt-notification .kt-notification__item h6 {
    width: 100%;
}
#main_test_review_page .kt-notification__item {
    margin-top: 10px
}
#main_test_review_page .kt-notification__item > .row {
    width: 100%;
}
#main_test_review_page .kt-notification__item > p {
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 16px;
    color: #646c9a;
}
#main_test_review_page .kt-notification__item > audio {
    margin-top: 20px;
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_review_page .kt-notification__item audio:focus {
    outline: none !important;
}
#main_test_review_page .kt-notification .kt-notification__item .kt-notification-v2 {
    width: 100%;
    margin-top: 10px;
}
#main_test_review_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item {
    padding: 5px;
}
#main_test_review_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item.selected .kt-notification-v2__item-icon {
    background: #646c9a;
    color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; 
}
#main_test_review_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}
#main_test_review_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-badge {
    margin-left: 5px;
    width: 30px;
    height: 30px;
    min-width: 30px;
}
#main_test_review_page .question-configuration-extra-block img {
    max-width: 100px;
    max-height: 100px;
}
#main_test_review_page .question-configuration-extra-block audio {
    height: 50px;
    width: 200px;
    background: #f1f3f4;
}
#main_test_review_page .question-configuration-extra-block audio:focus {
    outline: none !important;
}
#main_test_review_page .question-configuration-extra-block video {
    max-width: 200px;
    max-height: 200px;
}

#main_test_review_page div.full-width {
    width: 100%;
}

#main_test_review_page span.blank-to-be-filled {
    padding-left:5px;padding-right:5px;
    color: #f7a325;
    font-weight: 500;
    font-size: large;
}

#main_test_review_page div.paragraph {
}



#main_test_prepare_page .kt-section {
    margin-bottom: 0;
}
#main_test_prepare_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_test_prepare_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__pic {
    font-size: 50px;
}
#main_test_prepare_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info div {
    margin-top: 10px;
    line-height: 20px;
}
#main_test_prepare_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info br {
    content: "";
    margin: 2em;
    display: block;
    font-size: 24%;
}
#main_test_prepare_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info p {
    margin-bottom: 0;
}
#main_test_prepare_page .kt-portlet .kt-portlet__foot .hook-text {
    font-size: 14px;
}
#main_test_prepare_page .kt-portlet .kt-portlet__foot .kt-link {
    font-size: 16px;
}


#main_test_take_page .kt-portlet .kt-portlet__head {
    display: block;
    padding: 15px 25px;
    min-height: unset;
}
#main_test_take_page .kt-portlet .kt-portlet__foot {
    display: block;
    padding: 15px 25px;
}
#main_test_take_page .kt-portlet .larger-footer {
    display: block;
    padding: 5px 35px;
    height: 95px;
}
#main_test_take_page .kt-portlet .correct-answer {
    background: #daefdc !important;
}
#main_test_take_page .kt-portlet .incorrect-answer {
    background: #f8d6d8 !important;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination {
    justify-content: center;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links li:hover {
    background-color: transparent;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links li:hover a {
    color: #74788d;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links li.answered {
    background-color: transparent;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links li.answered a {
    color: #0abb87;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links li.answered:hover  {
    background-color: transparent;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links li.answered a:hover {
    color: #0abb87;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links .kt-pagination__link--active {
    background: #a2a5b9 !important;#
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links .kt-pagination__link--active a {
    color: #FFF !important;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links .kt-pagination__link--active.answered {
    background: #0abb87 !important;
}
#main_test_take_page .kt-portlet .kt-portlet__foot .kt-pagination .kt-pagination__links .kt-pagination__link--active.answered a {
    color: #FFF !important;
}
#main_test_take_page .kt-section {
    margin-bottom: 0;
}
#main_test_take_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}
#main_test_take_page .section-media {
    background-color: #E1E5EC;
    padding: 15px;
}
#main_test_take_page .section-extra-block {
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
    display: flex;
    flex-direction: column;
}

#main_test_take_page .section-extra-block .centered-block {
    justify-content: center;
    align-items: center;
}

#main_test_take_page .section-extra-block .block-control {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
}
#main_test_take_page .section-extra-block > * {
    margin-top: 20px;
}
#main_test_take_page .section-extra-block > *:first-child{
    margin-top: 0;
}
#main_test_take_page .section-extra-block div {
    width: 100%;
}
#main_test_take_page .section-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_take_page .section-extra-block img {
    width: 100%;
}
#main_test_take_page .section-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_take_page .section-extra-block audio:focus {
    outline: none !important;
}
#main_test_take_page .section-extra-block video {
    width: 100%;
}
#main_test_take_page .question-media {
    background-color: #E1E5EC;
    padding: 15px;
    height: fit-content;
}
#main_test_take_page .question-media-for-kid-test {
    padding: 15px;
    height: fit-content;
}
#main_test_take_page .question-left-extra-block {
    position: -webkit-sticky;
    position: sticky;
    top: 140px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#main_test_take_page .question-left-extra-block .block-control {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
}
#main_test_take_page .question-left-extra-block > * {
    margin-top: 20px;
}
#main_test_take_page .question-left-extra-block > *:first-child{
    margin-top: 0;
}
#main_test_take_page .question-left-extra-block div {
    width: 100%;
    height: 100%;
}
#main_test_take_page .question-left-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_take_page .question-left-extra-block img {
    width: 100%;
    height: 100%;
}
#main_test_take_page .question-left-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_take_page .question-left-extra-block audio:focus {
    outline: none !important;
}
#main_test_take_page .question-left-extra-block video {
    width: 100%;
}
#main_test_take_page .question-top-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    background-color: #E1E5EC;
    padding: 15px;
    position: relative;
}
#main_test_take_page .question-top-extra-block .block-control {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 0;
}
#main_test_take_page .question-top-extra-block > * {
    margin-top: 20px;
}
#main_test_take_page .question-top-extra-block > *:first-child{
    margin-top: 0;
}
#main_test_take_page .question-top-extra-block div {
    width: 100%;
}
#main_test_take_page .question-top-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_take_page .question-top-extra-block img {
    width: 100%;
}
#main_test_take_page .question-top-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_take_page .question-top-extra-block audio:focus {
    outline: none !important;
}
#main_test_take_page .question-top-extra-block video {
    width: 100%;
}
#main_test_take_page .kt-notification .kt-notification__item .row {
    width: 100%;
    height:100%;
}
#main_test_take_page .kt-notification .kt-notification__item:after {
    content: ''
}
#main_test_take_page .kt-notification .kt-notification__item {
    flex-direction: column;
    padding: 15px;
    min-width: 300px;
}
#main_test_take_page .kt-notification .kt-notification__item h4 {
    width: 100%;
}
#main_test_take_page .kt-notification .kt-notification__item h6 {
    width: 100%;
}
#main_test_take_page .kt-notification .kt-notification__item .kt-notification-v2 {
    width: 100%;
    margin-top: 10px;
}
#main_test_take_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item {
    padding: 5px;
}
#main_test_take_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item.selected {
    background-color: #82cfff;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
#main_test_take_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item.selected .kt-notification-v2__item-icon {
    background-color: #ebedf2;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; 
}
#main_test_take_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}
#main_test_take_page .question-configuration-extra-block img {
    max-width: 100px;
    max-height: 100px;
}
#main_test_take_page .question-configuration-extra-block audio {
    height: 50px;
    width: 200px;
    background: #f1f3f4;
}
#main_test_take_page .question-configuration-extra-block audio:focus {
    outline: none !important;
}
#main_test_take_page .question-configuration-extra-block video {
    max-width: 200px;
    max-height: 200px;
}
#main_test_take_page .form-group {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 10px;
}
#main_test_take_page .form-group textarea {
    resize: none;
}
#main_test_take_page .record-block {
    margin-top: 15px;
    background-color: #555555;
    width: 100%;
}
#main_test_take_page .record-block .record-canvas {
    width: 100%;
}
#main_test_take_page .record-block .record-controls {
    margin-bottom: 30px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
#main_test_take_page .record-block .record-playback {
    
}
#main_test_take_page .record-block .record-playback audio {
    width: 100%;
    height: 50px;
    background: #f1f3f4;
}
#main_test_take_page .record-block .record-playback audio:focus {
    outline: none;
}

#main_test_take_page div.full-width {
    width: 100%;
}

#main_test_take_page div.paragraph {
}

#main_test_take_page div.blank-toggle {
    display: inline-block;    
}

#main_test_take_page div.blank-toggle-icon {
    width: 25px;
    height: 25px;
    background: white;
}

#main_test_take_page a.blank-to-be-filled {
    padding-left:5px;padding-right:5px;
    font-weight: 500;
    font-size: large;
    color: #f7a325;
 
}

#main_test_take_page ul.phrase-list {
    width: 400px;
    border: 2px solid #f7a325 !important;
    border-radius: 25px;
    line-height: 150%;
    z-index: 99;
    font-size: 1.2rem;
}

#main_test_take_page input.user_input_blank {
    border: none;
    border: solid 2px #f7a325;
    border-radius: 25px;
    width: 100px;
    display: inline-block;
}

#main_test_take_page a.hint-button {
    padding: 10px 25px;
}

#main_test_take_page div.hint-area {
    height: 100%;
}

#main_test_take_page img.footer-img-1 {
    position: relative;
    top: -65px;
    width: 50px;
}

#main_test_take_page img.footer-img-2-wrong {
    position: relative;
    width: 90px;
    top: -50px;
}

#main_test_take_page img.footer-img-2-correct {
    position: relative;
    width: 100px;
    top: -50px;
}

#main_test_take_page div.dragging-draggable-element, #main_test_take_page div.dragging-reorder-element {
    background: orange;
}

#select_phrase_to_fill_in_the_blank_modal div.phrase-to-select {
    display: inline-block;
    border: 2px solid orange;
    border-radius: 25px;
    padding: 10px;
    margin: 15px;
    min-width: 100px
}

#main_test_take_page .selected-orange-bg {
    background-color: orange !important;
    border-radius: 20px !important;
}

#main_test_take_page .progress-4kid {
    background-color: lightgrey;
    border-radius: 20px;
}

#main_test_take_page .progress-bar-4kid {
    background-color: #ee880c;
    border-radius: 20px;
}

#main_test_take_page .btn-4kid {
    border: 1px solid orange;
    border-radius: 20px;
    color: white;
    background-color: orange; 
    background-image: linear-gradient(#f8cd2b, #ea6f19);
    font-weight: bold;
}

#main_test_take_page .btn-4kid:hover {
    color: black;
    background-color: white; 
    background-image: none;
    border-color: black;
}

#main_test_take_page textarea.text-input-for-kid {
    border: 3px solid orange;
    border-radius: 20px;
}



#main_test_done_page .kt-section {
    margin-bottom: 0
}
#main_test_done_page .kt-section .kt-section__content p {
    margin-bottom: 0
}
#main_test_done_page .kt-widget .kt-widget__top .kt-widget__pic {
    font-size: 40px;
}
#main_test_done_page .kt-widget .kt-widget__top .kt-widget__content .kt-widget__info {
    flex-direction: column;
}
#main_test_done_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__desc {
    width: 100%;
    line-height: 25px;
}
#main_test_done_page .kt-widget.kt-widget--user-profile-3 .kt-widget__top .kt-widget__content .kt-widget__info .kt-widget__progress {
    max-width: unset;
    margin-bottom: 5px;
}
#main_test_done_page .kt-widget.kt-widget--user-profile-3 .kt-widget__bottom:nth-child(3) {
    border-top: none;
}
#main_test_done_page .section-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#main_test_done_page .section-extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_done_page .section-extra-block > div:first-child{
    margin-top: 0;
}
#main_test_done_page .section-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_done_page .section-extra-block img {
    width: 100%;
}
#main_test_done_page .section-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_done_page .section-extra-block audio:focus {
    outline: none !important;
}
#main_test_done_page .section-extra-block video {
    width: 100%;
}
#main_test_done_page .answer-top-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
#main_test_done_page .answer-top-extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_done_page .answer-top-extra-block > div:first-child {
    margin-top: 0;
}
#main_test_done_page .answer-top-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_done_page .answer-top-extra-block img {
    width: 100%;
}
#main_test_done_page .answer-top-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_done_page .answer-top-extra-block audio:focus {
    outline: none !important;
}
#main_test_done_page .answer-top-extra-block video {
    width: 100%;
}
#main_test_done_page .answer-left-extra-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#main_test_done_page .answer-left-extra-block > div {
    margin-top: 20px;
    width: 100%;
}
#main_test_done_page .answer-left-extra-block > div:first-child {
    margin-top: 0;
}
#main_test_done_page .answer-left-extra-block p {
    width: 100%;
    margin-bottom: 0;
    text-align: center;
    font-size: 1.5rem;
}
#main_test_done_page .answer-left-extra-block img {
    width: 100%;
}
#main_test_done_page .answer-left-extra-block audio {
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_done_page .answer-left-extra-block audio:focus {
    outline: none !important;
}
#main_test_done_page .answer-left-extra-block video {
    width: 100%;
}
#main_test_done_page .kt-notification .kt-notification__item:after {
    content: ''
}
#main_test_done_page .kt-notification .kt-notification__item {
    flex-direction: column;
    padding: 15px;
}
#main_test_done_page .kt-notification .kt-notification__item h5 {
    width: 100%;
}
#main_test_done_page .kt-notification .kt-notification__item h5 a {
    font-size: 20px;
}
#main_test_done_page .kt-notification .kt-notification__item h6 {
    width: 100%;
}
#main_test_done_page .kt-notification__item {
    margin-top: 10px
}
#main_test_done_page .kt-notification__item > .row {
    width: 100%;
}
#main_test_done_page .kt-notification__item > p {
    width: 100%;
    margin-bottom: 0;
    margin-top: 10px;
    font-size: 16px;
    color: #646c9a;
}
#main_test_done_page .kt-notification__item > audio {
    margin-top: 20px;
    height: 50px;
    width: 100%;
    background: #f1f3f4;
}
#main_test_done_page .kt-notification__item audio:focus {
    outline: none !important;
}
#main_test_done_page .kt-notification .kt-notification__item .kt-notification-v2 {
    width: 100%;
    margin-top: 10px;
}
#main_test_done_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item {
    padding: 5px;
}
#main_test_done_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item.selected .kt-notification-v2__item-icon {
    background: #646c9a;
    color: #fff !important;
    -webkit-transition: all 0.3s;
    transition: all 0.3s; 
}
#main_test_done_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-notification-v2__item-icon {
    width: 30px;
    height: 30px;
    min-width: 30px;
}
#main_test_done_page .kt-notification .kt-notification__item .kt-notification-v2 .kt-notification-v2__item .kt-badge {
    margin-left: 5px;
    width: 30px;
    height: 30px;
}
#main_test_done_page .question-configuration-extra-block img {
    max-width: 100px;
    max-height: 100px;
}
#main_test_done_page .question-configuration-extra-block audio {
    height: 50px;
    width: 200px;
    background: #f1f3f4;
}
#main_test_done_page .question-configuration-extra-block audio:focus {
    outline: none !important;
}
#main_test_done_page .question-configuration-extra-block video {
    max-width: 200px;
    max-height: 200px;
}

#main_test_done_page div.full-width {
    width: 100%;
}

#main_test_done_page span.blank-to-be-filled {
    padding-left:5px;padding-right:5px;
    color: #f7a325;
    font-weight: 500;
    font-size: large;
}



#main_test_thank_page .kt-section {
    margin-bottom: 0;
}
#main_test_thank_page .kt-section .kt-section__content p {
    margin-bottom: 0;
}

#ranking .table-custom{
    border-collapse: collapse;
}
#ranking .center{
    display: grid;
    justify-items: center;
}
#ranking .title-table{
    color:#000;
}
 
#ranking .text-left {
    text-align: left;
}
#ranking grade {
    margin-top: 50px;
    justify-content: end;
    display: grid;
}
#ranking td {
    border: 1px solid #ddd;
} 
#ranking th {
    border: 1px solid #ddd;
} 
#ranking tr{
    background-color: white;
    color: black;
    text-align: center;
    font-weight: bold;
} 

#ranking tr:hover {background-color: #fff; color:#000}


#ranking th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #0bbb87;
    color: white;
    font-size:16px;
}
#ranking .grade{
    width: 100px;
    margin-right: 5px; 
}
#ranking .grade-view{
    margin-top: 50px;
    display: grid; 
    justify-items:end;
}
#ranking .grade-item{
    cursor: pointer;
    width: 80px;
    height:40px;
    background-color: #1b478a;
    font-size: 15px;
    color: white;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    display: grid;
    align-items:center;
    margin-bottom: 5px;
}
#ranking .grade-item:hover{
    background-color: #2946d6;
    color: white;
}
.font-pattaya{
    font-family:Pattaya
}
#ranking_nation table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  #ranking_nation  th {
    font-size: 15px;
  }
  #ranking_nation td, th {
    border: 1px solid #dddddd;
    padding: 8px;
    text-align: center;
  }
  #ranking_nation td {
    font-size: 13px;
    font-weight: 500;
    background: aliceblue;
  }
  .question-left-extra-block .expand-icon{
      opacity: 0;
      transition-duration: 0.3s;
      top: 5%;
      right: 5%;
      transition-property: opacity;
      padding:10px
      
  }
  .question-left-extra-block:hover .expand-icon{
      opacity:1;
  }
  .question-left-extra-block .refresh-icon{
    opacity: 0;
    transition-duration: 0.3s;
    top: 5%;
    left: 5%;
    transition-property: opacity;
    padding:10px
    
    }
    .question-left-extra-block:hover .refresh-icon{
        opacity:1;
    }
  .mh-500{
      max-height:500px;
  }
  .modal-expand-img-header h1,
  .modal-expand-img-header h2,
  .modal-expand-img-header h3,
  .modal-expand-img-header h4,
  .modal-expand-img-header h5,
  .modal-expand-img-header p{
    font-size:1.5rem;
    margin:0;
  }
`;
